.local-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #f6f6f675;
  left: 0;
  top: 0;
  z-index: 100;
  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .fa {
      font-size: 3em;
    }
  }
}
