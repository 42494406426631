@import './../../../styles/theme';
.back-btn {
    button {
        color: orangered;
        font-size: 1.4rem;
        transform: translate(-250%);
        .icon {
            position: relative;
            top: 0.6rem;
            font-size: 2.2rem;
        }
    }
}

@media screen and (max-width: 480px) {
        .back-btn {
            top: -0.6rem;
            button {
                font-size: 2.4rem;
                .icon {
                    top: 0.6rem;
                    font-size: 2.8rem;
                }
            }
        }
}