@import './../../../styles/theme';

.category-container {
    overflow-y: auto;
    height: 100vh;
    .category {
        .action {
            float: right;
            a {
                color: $THEME-COLOR-ORANGE-1;
                font-weight: 600;
                ion-icon {
                    margin-right: -1.5rem;
                    font-size: 2.2rem;
                    position: relative;
                    top: -1.5rem;
                    // pointer-events: none;
                }
            }
        }
    }
    .products {
        .action {
            position: absolute;
            top: -2rem;
            right: 0;
            a {
                color: $THEME-COLOR-ORANGE-1;
                font-weight: 600;
                ion-icon {
                    margin-right: 0.5rem;
                    font-size: 2rem;
                    position: relative;
                    top: 0.4rem;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .products {
        line-height: 1.5;
        .image {
            img {
                height: initial;
            }
        }
        .action {
            a {
                ion-icon {
                    font-size: 3rem;
                    top: -0.5rem;
                }
            }
        }
        .ant-input-number-group-wrapper {
            display: block;
            width: 14rem;
            margin-top: .5rem;
        }
    }
}