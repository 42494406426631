@mixin font-size-weight($size, $weight) {
  font-size: ($size * 1rem) !important;
  font-weight: $weight !important;
}

@mixin margin($top: null, $right: null, $bottom: null, $left: null) {
  @if $top !=null {
    margin-top: $top * 1rem;
  }

  @if $right !=null {
    margin-right: $right * 1rem;
  }

  @if $bottom !=null {
    margin-bottom: $bottom * 1rem;
  }

  @if $left !=null {
    margin-left: $left * 1rem;
  }
}

@mixin mixin-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@mixin mixin-text-uppercase {
  text-transform: uppercase;
}

@mixin mixin-text-capitalize {
  text-transform: capitalize;
}

@mixin mixin-text-titlecase {
  *::first-letter {
    text-transform: uppercase;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin font-variants($class, $font-size) {
  .font-#{$class}-extrabold {
    @include font-size-weight($font-size, $FONT-EXTRABOLD);
  }

  .font-#{$class}-bold {
    @include font-size-weight($font-size, $FONT-BOLD);
  }

  .font-#{$class}-semibold {
    @include font-size-weight($font-size, $FONT-SEMIBOLD);
  }

  .font-#{$class}-regular {
    @include font-size-weight($font-size, $FONT-REGULAR);
  }
  .font-#{$class}-thin {
    @include font-size-weight($font-size, $FONT-THIN);
  }

  .font-#{$class}-light {
    @include font-size-weight($font-size, $FONT-LIGHT);
  }
}
