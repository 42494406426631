@import "../../../../styles/theme";

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 5rem;
  width: 100%;
  padding: 0.5rem 3rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 1px 1px 1px rgba(9, 9, 9, 0.23);
  z-index: 9;

  &__logo {
    width: 9rem;
  }

  // .brand-name {
  //   background: url('/assets/logo.jpg');
  //   width: 10rem;
  //   height: 3rem;
  // }
  .navigation-menu {
    margin-left: auto;

    ul {
      display: flex;
      padding: 0;
      margin-bottom: 0;
      li {
        list-style-type: none;
        margin: 0 1.5rem;

        a, button {
          text-decoration: none;
          display: block;
          width: 100%;
          font-size: 1.6rem;

          &.dropdown-menu {
            padding: 0 1.5rem;
          }
        }
      }
    }
  }
  .hamburger {
    border: 0;
    height: 8rem;
    width: 8rem;
    padding: 1rem;
    border-radius: 50%;
    // background-color: #b0b1b5;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    display: none;

    // &:hover {
    //   background-color: #b0b1b5;
    // }
  }
  
}

@media screen and (max-width: 480px) {
  .navigation {
    padding: 0.5rem 2rem;
    background: none;
    box-shadow: none;
    width: fit-content;
    .brand-name {
      display: none;
    }
    .navigation-menu {
      &.expanded {
        width: 60vw;
      }
      ul {
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: white;
        border-top: 1px solid black;
        display: none;

        li {
          text-align: center;
          margin: 0;

          &:hover {
            background-color: #eee;
          }

          a, button {
            color: black;
            width: 100%;
            padding: 1.5rem 0;
            font-size: 2rem;
          }
        }
      }
      &.expanded {
        ul {
          display: block;
        }
      }
    }

    .hamburger {
      display: block;
      height: 6rem;
      width: 6rem;
      top: 75%;
    }
  }
}
