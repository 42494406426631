@import './../../styles/theme';

.container {
    padding-top: 5rem;

    .card {
        background: $THEME-COLOR-WHITE-1;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        padding: 2rem;
        border: 1px solid $THEME-BORDER-COLOR-1;
        margin-bottom: 3.5rem;

        &__body {
            &__header {
                color: $THEME-COLOR-BLACK-1;
            }
        }
        &__link {
            color: orangered;
            font-weight: bold;
        }
        .icon {
            position: relative;
            top: 0.6rem;
            font-size: 2rem;
            left: 1rem;
        }
    }
}

@media (max-width: 480px) {
    .container {
        .card {
            &__link {
                font-size: 2rem;
                margin-top: 1rem;
                .icon {
                    font-size: 2.8rem;
                }
            }
        }
    }
}