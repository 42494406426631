@import './../../styles/theme';

.product-container {
    padding-top: 3rem;
    overflow-y: auto;
    .back-btn {
        margin-top: -0.6rem;
        button {
            color: orangered;
            font-size: 1.4rem;
            .icon {
                position: relative;
                top: 0.6rem;
                font-size: 2.2rem;
            }
        }
    }
    .value {
        text-transform: uppercase;
    }
    .modified {
        color: $THEME-COLOR-GRAY-1;
        margin-left: 2rem;
        font-size: 1.2rem;
    }
    .product-detail {
        margin-top: 4rem;
    }
    .details {
        line-height: 2;
        padding-left: 4rem;
        &__header {
            .quantity {
                margin-left: 2rem;
                color: $THEME-COLOR-GRAY-2;
            }
            .action {
                float: right;
                a {
                    color: $THEME-COLOR-ORANGE-1;
                    font-weight: 600;
                    ion-icon {
                        font-size: 2rem;
                        position: relative;
                        top: 0.4rem;
                    }
                }
            }
        }
        &__size {
            color: $THEME-COLOR-GRAY-2;
        }
        &__variant {
            background: $THEME-COLOR-ORANGE-1;
            color: $THEME-COLOR-WHITE-1;
            display: inline-block;
            padding: .2rem 1rem;
            border-radius: 5px;
        }
    }
    .linked_products {
        .action {
            position: absolute;
            top: -2rem;
            right: 0;
            a {
                color: $THEME-COLOR-ORANGE-1;
                font-weight: 600;
                ion-icon {
                    margin-right: 0;
                    font-size: 1.4rem;
                    position: relative;
                    top: 0.2rem;
                }
            }
        }
    }
    
}

.link-image {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
}
.ant-tag {
    // border: none;
    // background: none;
    .link-image {
        display: none;
    }
    .ant-tag-close-icon {
        position: relative;
        top: -2px;
    }
}
.anticon-check {
    margin-top: 10px;
}



@media (max-width: 480px) {
    .product-container {
        .back-btn {
            margin-top: -0.6rem;
            padding-right: 0;
            button {
                font-size: 2rem;
                .icon {
                    top: 0.6rem;
                    font-size: 2.4rem;
                }
            }
        }
        .title-header {
            padding: 0;
        }
        .details {
            &__header {
                .action {
                    position: fixed;
                    top: 2rem;
                    right: 2rem;
                    a {
                        font-size: 2rem;
                        ion-icon {
                            font-size: 3rem;
                        }
                    }
                    &.edit {
                        right: initial;
                        left: 10rem;
                    }
                }
            }
        }
    }
}

