@import "~antd/dist/antd.css";
@import "styles/bootstrap";
@import "styles/toastr";
@import "styles/mixins";
@import "styles/theme";
@import "styles/fonts";
@import "styles/typography";
@import "styles/common";
@import "styles/form";
@import "styles/input";
@import "styles/input.range";
@import "styles/icons";
@import "styles/media";
@import "styles/antd";

button, a {
    &.link {
        background: transparent;
        padding: 0;
    }
    &.add-btn {
        padding: 1rem 1.5rem;
        background-color: $THEME-COLOR-ORANGE-1;
        color: $THEME-COLOR-WHITE-1;
        font-weight: 600;
        border-radius: 5px;

        &:hover {
            color: $THEME-COLOR-WHITE-1;
        }
    }
}

@media (max-width: 480px) {
    button, a {
        &.add-btn {
            padding: 1.2rem 1.8rem;
            font-size: 2rem;
            display: inline-block;
        }
    }
}
