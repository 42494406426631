.loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #f6f6f675;
    left: 0;
    top: 0;
    z-index: 99999;
    .spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .fa {
            font-size: 3em;
        }
    }
}