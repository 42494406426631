@import './../../../styles/theme';

.products-container{
    overflow-y: auto;
    height: 100vh;
    .image {
        img {
            width: 100%;
            // height: 10rem;
        }
        .placeholder {
            background-color: #eee;
            width: 100%;
            height: 15rem;
        }
    }
    
    .uppercase {
        text-transform: uppercase;
    }
    .ant-input-number-disabled {
        background: $THEME-COLOR-WHITE-1;
        color: $THEME-COLOR-BLACK-1;
        input {
            cursor: default;
        }
    }
    .ant-input-number-group-wrapper {
        width: 10rem;
        // margin-left: 1rem;
        .ant-input-number-handler-wrap {
            display: none;
        }
        .ant-input-number-input {
            padding: 0 5px;
            text-align: center;
        }
        .ant-input-number-group-addon {
            padding: 0;
            button {
                padding: 4px 10px;
            }
        }
    }
    .action {
        position: absolute;
        top: -2rem;
        right: 0;
        a {
            color: $THEME-COLOR-ORANGE-1;
            font-weight: 600;
            ion-icon {
                margin-right: 0.5rem;
                font-size: 2rem;
                position: relative;
                top: 0.4rem;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .products-container {
        line-height: 1.5;
        .image {
            img {
                height: initial;
            }
        }
        .action {
            a {
                ion-icon {
                    font-size: 3rem;
                    top: -0.5rem;
                }
            }
        }
        .ant-input-number-group-wrapper {
            display: block;
            width: 14rem;
            margin-top: .5rem;
        }
    }
}