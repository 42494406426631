@import "./theme";

.Toastify__toast-container {
  width: 40rem;
  // top: 10px;

  .Toastify__toast-body {
    margin: 0.5rem;
    flex: 1 1 auto;
    font-size: 1.4rem;
  }

  .Toastify__toast {
    max-width: 40em !important;
    min-width: 15em !important;
    width: auto !important;
    max-height: 10em;
    overflow-y: auto;

    &.toast-top-right {
      right: 2px !important;
    }

    &.Toastify__toast--success {
      background-color: $THEME-COLOR-GREEN-2;
    }

    &.Toastify__toast--error {
      background-color: $THEME-COLOR-RED-1;
    }

    &.Toastify__toast--warning {
      background-color: $THEME-COLOR-YELLOW-1;
      color: $THEME-COLOR-MAROON-1;
    }

    &.Toastify__toast--info {
      background-color: $THEME-COLOR-BLUE-BG-1;
    }
  }

  &.snackbar {
    width: 350px;
    .snackbar-btn {
      background: $BRAND-COLOR-BLUE-1;
      color: $THEME-COLOR-WHITE-1;
      float: right;
      padding: 0.5em 1.2em;
      font-size: 1.2rem;
      font-weight: 500;
      position: relative;
      top: 0;
    }
    .Toastify__toast-body {
      margin-bottom: 0;
      margin-top: 0;
    }

    .Toastify__toast {
      background: $THEME-COLOR-BLUE-BG-6 !important;
      color: $BRAND-COLOR-BLUE-1;
      min-height: initial;
      padding: 12px 12px;
    }
    .message {
      float: left;
    }
    .refresh-icon {
      display: inline-block;
      height: 1.2em;
      width: 1.2em;
      margin-right: 0.5em;
      margin-top: 0.2em;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
      position: relative;
      top: 0.2em;
      background-image: url("https://assets.prophesy.tech/call_analytics/assets/icons/refresh_blue.svg");
    }
    .Toastify__close-button {
      opacity: 1;
      top: 0;
      margin-left: 0.8em;
      position: relative;
      svg {
        color: $BRAND-COLOR-BLUE-1;
      }
    }
  }
}
