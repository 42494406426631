.form {
  width: 100%;

  .form-row {
    padding: 1rem 2rem;
    margin-bottom: 1em;

    .form-group {
      width: 100%;

      label {
        display: block;
        &.hidden {
          visibility: hidden;
        }
      }
      input[type="text"],
      input[type="number"] {
        &.form-control {
          min-height: 38px;
        }
      }
      .ant-select-selector {
        min-height: 38px;
      }
      .form-control {
        border-radius: 5rem;
      }

      .form-control,
      input,
      .selectbox,
      .ant-select-selector {
        // border-radius: 9px;
        // border: 1px solid $THEME-COLOR-WHITE-1;
        // background-color: $THEME-COLOR-WHITE-1;
        font-size: 0.9em;

        &::placeholder {
          color: $THEME-COLOR-BLACK-4;
          opacity: 0.4;
        }

        &[type="radio"] {
          -webkit-appearance: none;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          outline: none;
          border: 1px solid $THEME-COLOR-BLUE-BG-1;

          &:before {
            content: "";
            display: block;
            width: 0.5em;
            height: 0.5em;
            margin: 20% auto;
            border-radius: 50%;
          }

          &:checked {
            border-color: $THEME-COLOR-BLUE-BG-1;

            &:before {
              background: $THEME-COLOR-BLUE-BG-1;
            }
          }
        }

        &[type="file"] {
          background: none;
          border: none;
          border-radius: 0;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .form-check-label {
        font-size: 0.9em;
      }
    }
  }
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  text-align: left;
}

.card-box {
  border-radius: 4px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

@media (max-width: 480px) {
  .product-form .ant-form-vertical .ant-select .ant-select-selector .ant-select-selection-item, .product-form .ant-form-vertical .ant-select .ant-select-selector .ant-select-selection-placeholder {
    margin-top: 0;
  }
}
