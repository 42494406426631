html {
  font-size: 62.5%; // now, 10px => 1rem
  margin: 0;
}

body {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.75em;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  color: $THEME-FONT-COLOR-BLACK-1;
  letter-spacing: 0;
  background-color: $THEME-COLOR-BLUE-BG-4;

  #root {
    .container-fluid {
      height: 100%;

      .container-view {
        height: 100%;

        .heading {
          margin: 2rem auto;
        }
      }
    }
  }

  * {
    outline: none !important;
    font-family: $FONT-FAMILY;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      cursor: default;
    }

    div[contenteditable="true"] {
      outline: none;
    }

    a {
      &,
      &:hover,
      &:focus {
        color: $THEME-FONT-COLOR-BLACK-1;
        text-decoration: none;
      }
    }

    button {
      cursor: pointer;
    }
  }

  .shadow {
    box-shadow: 5px 10px 24px #eef0f8;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .clickable {
    cursor: pointer;
  }

  .no-margin {
    margin: 0;
  }

  .display-inline {
    display: inline-block !important;
  }

  .display-block {
    display: block !important;
  }

  .visible-none {
    visibility: hidden;
  }

  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .hoverable {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .lower {
    text-transform: lowercase;
  }

  textarea {
    resize: none;
  }
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.priority {
  font-weight: $FONT-BOLD;
  font-size: 1.2rem;
  padding: 0.2em 1em;
  border-radius: 2em;
  color: $THEME-COLOR-WHITE-1;
  &.p1 {
    background-color: $THEME-COLOR-ORANGE-1;
  }
  &.p2 {
    background-color: $THEME-COLOR-PEACH-1;
  }
  &.p3 {
    background-color: $THEME-COLOR-GREEN-4;
  }
}

.audio-control {
  color: $BRAND-COLOR-BLUE-2;
  padding: 0;
  font-size: 1.8em;
  background: none;
  border: none;
}

.page-container {
  .container {
    max-width: 85%;
    padding: 0;
  }
  .page-header {
    background: $THEME-COLOR-WHITE-1;
    padding: 3rem 0;
    &__title {
      h1 {
        color: $THEME-COLOR-BLACK-4;
        line-height: 1.4;
      }
    }
    .header-actions {
      &__action-icon {
        width: 1.3rem;
        img {
          width: 100%;
        }
      }
    }
  }
  .page-content {
    padding: 3rem 0;
  }
}

.color-black-primary {
  color: $THEME-COLOR-BLACK-4;
}

.color-brand-blue-1 {
  color: $BRAND-COLOR-BLUE-1;
}

#main {
  overflow: auto;
}
