@import "../../styles/theme";

.login {
  background: $THEME-COLOR-WHITE-1;
  position: absolute;
  top: 0;
  z-index: 999;
  padding: 10em;
  &.details {
    overflow: auto;
    .account-container {
      top: 2rem;
      transform: translate(-50%, 0);
    }
  }
  // &.merchant {
  //   overflow-y: scroll;
  //   padding-top: 5rem;
  //   position: relative;
  //   .account-container {
  //     position: absolute;
  //     top: 0;
  //     transform: translate(-50%, 0);
  //   }
  // }
  .account-container {
    position: absolute;
    background: $THEME-COLOR-WHITE-1;
    border: 1.5px solid $THEME-COLOR-GRAY-4;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2em 3em;
    // width: 26em;
    // text-align: center;
    border-radius: 5px;
    .circle {
      background: $THEME-COLOR-GRAY-BG-1;
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      display: inline-block;
    }
    .account-form {
      width: 21em;
      display: inline-block;
    }
    .subtitle {
      color: $THEME-COLOR-GRAY-2;
      margin-top: 1rem;
    }
    .banner {
      display: inline-block;
      width: 20em;

      .section-image {
        position: absolute;
        top: 50%;
        right: 3em;
        transform: translateY(-50%);
        width: 15em;
      }
    }
    .brand-title {
      font-size: 1.8rem;
      margin-bottom: 2.4rem;
      color: $THEME-COLOR-GRAY-1;
    }

    .ant-form-item-label {
      > label {
        font-size: 1.3rem;
        color: $THEME-COLOR-GRAY-5;
        &.ant-form-item-required {
          &::before {
            display: none;
          }
        }
      }
    }
    .ant-form-item {
      margin-bottom: 0.5em;
    }
    .ant-input {
      border-color: $THEME-COLOR-GRAY-4;
    }
    .ant-select-selection-item {
      text-align: left;
    }
    .help-text {
      font-size: 1.3rem;
      color: $THEME-COLOR-BLACK-4;
      margin-top: 0.5em;
    }
    .btn-submit {
      background: #ea5700;
      color: $THEME-COLOR-WHITE-1;
      // font-size: 1.4rem;
      font-weight: 600;
      padding: 0.5em 2em;
      border-radius: 5px;
      width: 100%;
    }
    .btn-link {
      background: transparent;
      color: #ea5700;
      // font-size: 1.3rem;
    }
    .btn-login {
      // float: right;
      margin-top: 1.5em;

    }
    .btn-forgot {
      padding: 0.5em 0;
    }
    .btn-forgotpassword {
      background: $THEME-COLOR-WHITE-3;
      color: $THEME-COLOR-GRAY-5;
    }
    .email {
      color: $THEME-COLOR-BLUE-1;
      margin-left: 0.5em;
    }
  }
}

// Mobile css styles
@media (max-width: 480px) {
  .login {
    padding: 2em;

    .account-container {
      border: none;
      padding: 0;
      .circle { 
        width: 7rem;
        height: 7rem;
        margin-bottom: 1rem;
      }
      .brand-title {
        font-size: 2.6rem;
        margin-bottom: 5rem;
      }
      .subtitle {
        margin-top: 1.5rem;
      }
      .account-form {
        width: 80vw;
        max-width: 35em;
      }
      .ant-form-item-label {
        > label {
          font-size: 2.2rem;
        }
      }
    }
  }
}

// .header {
//   display: none;
// }
