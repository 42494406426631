.carousel {
    padding: 0 1rem;
    .slick-slider {
        padding: 0 5px 0 25px;
        .slick-arrow {
            &:before {
                color: #000;
                font-size: 3rem;
            }
        }
        .slick-prev {
            left: -12px;
        }
    }
    
    .banner {
        img {
            width: 100%;
        }
    }
    .thumbnail {
        margin-top: 2rem;
        height: 90px;
        img {
            height: 100px;
        }
        .slick-slide {
            margin: 0 8px;
        }
    }
}

@media (max-width: 480px) {
    .carousel {
        .slick-slider {
            padding: 0 25px 0 25px;
            .slick-next {
                right: -10px;
            }
        }
    }
}
