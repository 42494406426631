@import "../../../styles/theme";

.profile {
  background: $THEME-COLOR-WHITE-1;
  .profile-container {
    padding: 2em 6em;

    .page-header {
      font-weight: 600;
      font-size: 1.2rem;
      color: $THEME-COLOR-BLUE-13;
      background: $THEME-COLOR-PEACH-4;
      border-radius: 8px;
      padding: .5em 2em;
      img {
        width: 1.8rem;
        margin-right: .7em;
        position: relative;
        top: -2px;
      }
    }
    .account-form {
      margin-top: 4em;
      background: $THEME-COLOR-WHITE-1;
      border: 1px solid $THEME-BORDER-COLOR-6;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 2em 2em;
    }
    .profile {
      background: $THEME-COLOR-BLUE-BG-8;
      border-radius: 8px;
      padding: 2em;
      display: inline-block;
      img {
        width: 8em;
      }
    }

    .separator {
      background: $THEME-COLOR-GRAY-4;
      width: 1px;
      height: 18rem;
      display: inline-block;
    }

    .ant-form-item {
      position: relative;
      margin-top: 1.2em;
      margin-bottom: 1.2em;
      .ant-form-item-label {
        position: absolute;
        top: -1em;
        left: 1em;
        padding: 0 .5em;
        background: $THEME-COLOR-WHITE-1;
        z-index: 2;

        >label {
          font-size: 1rem;
          color: $BRAND-COLOR-BLUE-1;
          font-weight: 600;
          &.ant-form-item-required {
            &::before {
              display: none;
            }
          }
        }
      }
    }


    /*Change background in autofill textbox*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $THEME-COLOR-WHITE-1 inset !important;
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
      -webkit-text-fill-color: $THEME-COLOR-GRAY-8 !important;
    }


    .ant-input {
      color: $THEME-COLOR-GRAY-8;
      border: 1px solid $THEME-BORDER-COLOR-6;
      box-shadow: 0px 2px 4px 2px rgba(211, 211, 211, 0.1);
      border-radius: 6px;
      font-size: 1.2rem;
      padding: .8rem 1rem;
      &[disabled] {
        color: $THEME-COLOR-GRAY-8;
        border: 1px solid $THEME-BORDER-COLOR-6;
        box-shadow: 0px 2px 4px 2px rgba(211, 211, 211, 0.1);
        background: $THEME-COLOR-WHITE-1;
      }
    }

    .ant-input-password {
        &.ant-input-affix-wrapper {
          border: 1px solid $THEME-BORDER-COLOR-6;
          box-shadow: 0px 2px 4px 2px rgba(211, 211, 211, 0.1);
          border-radius: 6px;
          padding: 0 1rem;
          .ant-input {
            border: none;
          }

        }
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow:none;
    }

    .help-text {
      font-size: 1.3rem;
      color: $THEME-COLOR-BLACK-4;
      margin-top: .5em;
    }

    .btn-submit {
      background: $BRAND-COLOR-BLUE-1;
      color: $THEME-COLOR-WHITE-1;
      font-size: 1.2rem;
      font-weight: 600;
      padding: .5em 1em;
      border-radius: 6px;
    }

    .secondary-btn {
      color: $THEME-COLOR-BLUE-1;
      font-size: 1.2rem;
      font-weight: 600;
      background: $THEME-COLOR-WHITE-1;
      border: 1px solid $BRAND-COLOR-BLUE-1;
      box-sizing: border-box;
      border-radius: 6px;
      padding: .5em 1em;
      margin-left: 1.5em;
    }


    .btn-forgot {
      padding: .5em 0;
      margin-top: 1.5em;
    }

    .btn-forgotpassword {
      background: $THEME-COLOR-WHITE-3;
      color: $THEME-COLOR-GRAY-5;
    }

    .email {
      color: $THEME-COLOR-BLUE-1;
      margin-left: .5em;
    }
  }
}
