@import './../../../styles/theme';

.category-form {
    padding: 3rem 5rem;
    width: 45rem;
    display: inline-block;
    background-color: #fff;
    position: relative;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);

    .back-btn {
        button {
            color: orangered;
            font-size: 1.4rem;
            .icon {
                position: relative;
                top: 0.6rem;
                font-size: 2.2rem;
            }
        }
    }

    .ant-form-vertical {
        .ant-form-item-label {
            > label {
                text-transform: uppercase;
                color: $THEME-COLOR-GRAY-1;
                font-weight: bold;
            }
        }
        .ant-form-item-control {
            .ant-input {
                background-color: $THEME-COLOR-BLUE-BG-4;
                border-color: #f4f4f9;
                height: 4rem;
                border-radius: 8px;
            }
            textarea {
                &.ant-input {
                    height: 8rem;
                }
            }
        }
        .ant-select {
            width: 100%;
            .ant-select-selector {
                background-color: $THEME-COLOR-BLUE-BG-4;
                border-color: #f4f4f9;
                height: 4rem;
                border-radius: 8px;

                input {
                    height: 4rem;
                }
                .ant-select-selection-placeholder {
                    margin-top: 4px;
                }
                .ant-select-selection-item {
                    margin-top: 4px;
                }
            }
        }
    }
    .btn-submit {
        background-color: $THEME-COLOR-ORANGE-1;
        color: $THEME-COLOR-WHITE-1;
        float: right;
        width: 45%;
        padding: .5rem 0;
        border-radius: 2rem;
    }
    .btn-reset {
        border: 1px solid $THEME-COLOR-ORANGE-1;
        color: $THEME-COLOR-ORANGE-1;
        width: 45%;
        padding: .5rem 0;
        border-radius: 2rem;
    }
}

@media screen and (max-width: 480px) {
    .category-form {
        .back-btn {
            top: -0.6rem;
            button {
                font-size: 2.4rem;
                .icon {
                    top: 0.6rem;
                    font-size: 2.8rem;
                }
            }
        }
    }
}