.button {
  border-radius: 999px;
  border: none;
  padding: 5px 20px;
  color: $THEME-BG-COLOR-WHITE;
  width: max-content;
  font-weight: 700;
  cursor: pointer;

  &.large {
    padding: 10px 32px;
  }

  &.primary {
    background-color: $THEME-COLOR-2;
  }
}
