.staff-container {
    .staff-list {
        .val {
            color: #3c5a80;
            font-weight: 700;
        }
        &.inactive {
            .val {
                color: #aaa;
            }
        }
    }
    .pill {
        background-color: black;
        color: white;
        padding: 4px 0;
        display: inline-block;
        border-radius: 20px;
        width: 8rem;

        &.active {
            background-color: #3c5a80;
        }
        &.inactive {
            background-color: #ee6c4d;
        }
        &.blocked {
            background-color: #e0fbfb;
        }
    }
    .link {
        color: orangered;
    }
}