// COLOR PALETTE
// Add new variables to JSVariables.scss also for use in javascript
$THEME-COLOR-WHITE-1: #ffffff;
$THEME-COLOR-WHITE-2: #fefefe;
$THEME-COLOR-WHITE-3: #f7f7f7;
$THEME-COLOR-WHITE-4: #ececec;

$THEME-COLOR-BLACK-1: #000000;
$THEME-COLOR-BLACK-2: #0e0e0e;
$THEME-COLOR-BLACK-3: #313131;
$THEME-COLOR-BLACK-4: #373737;
$THEME-COLOR-BLACK-5: #414040;
$THEME-COLOR-BLACK-6: #4a4a4a;
$THEME-COLOR-BLACK-7: #383838;
$THEME-COLOR-BLACK-8: #2c2c2c;
$THEME-COLOR-BLACK-9: #121212;
$THEME-COLOR-BLACK-10: #222222;
$THEME-COLOR-BLACK-11: #858585;

$THEME-COLOR-GRAY-1: #757575;
$THEME-COLOR-GRAY-2: #707070;
$THEME-COLOR-GRAY-3: #7a7a7a;
$THEME-COLOR-GRAY-4: #ededed;
$THEME-COLOR-GRAY-5: #858585;
$THEME-COLOR-GRAY-6: #979797;
$THEME-COLOR-GRAY-7: #505050;
$THEME-COLOR-GRAY-8: #88898f;
$THEME-COLOR-GRAY-9: #808080;


$THEME-COLOR-GRAY-BG-1: #cacaca;
$THEME-COLOR-GRAY-BG-2: #d1d1d1;
$THEME-COLOR-GRAY-BG-3: #d7d7d7;
$THEME-COLOR-GRAY-BG-4: #d8d8d8;
$THEME-COLOR-GRAY-BG-5: #dddddd;
$THEME-COLOR-GRAY-BG-6: #fcfdfe;
$THEME-COLOR-GRAY-BG-7: #eeeeee;
$THEME-COLOR-GRAY-BG-8: #e5e5e5;

$THEME-COLOR-GREEN-1: #227446;
$THEME-COLOR-GREEN-2: #1abaac;
$THEME-COLOR-GREEN-3: #34d2c4;
$THEME-COLOR-GREEN-4: #00b8a9;
$THEME-COLOR-GREEN-5: #6eb38a;
$THEME-COLOR-GREEN-6: #67bd70;
$THEME-COLOR-GREEN-7: #29cc39;
$THEME-COLOR-GREEN-8: #17bea3;
$THEME-COLOR-GREEN-9: #9de1d2;
$THEME-COLOR-GREEN-10: #25c9df;

$THEME_COLOR-GREEN-BG-1: #beefc3;
$THEME_COLOR-GREEN-BG-2: #eef9f0;
$THEME_COLOR-GREEN-BG-3: #d8f3ed;

$THEME-COLOR-YELLOW-1: #efef90;
$THEME-COLOR-YELLOW-2: #fef7e6;
$THEME-COLOR-YELLOW-3: #ffb86f;
$THEME-COLOR-YELLOW-4: #FF9142;
$THEME-COLOR-ORANGE-1: #ee6c4d;
$THEME-COLOR-ORANGE-2: #eb7e4e;
$THEME-COLOR-PEACH-1: #ffa561;
$THEME-COLOR-PEACH-2: #ff973f;
$THEME-COLOR-PEACH-4: #fcfbfc;

$THEME_COLOR-YELLOW-BG-1: #ffe6cc;

$THEME-COLOR-PEACH-BG-1: rgba($THEME-COLOR-PEACH-2, 0.14);

$THEME-COLOR-RED-1: #e23e57;
$THEME-COLOR-RED-2: #ee6b6b;
$THEME-COLOR-RED-3: #e62e2e;
$THEME-COLOR-RED-4: #ff878a;
$THEME-COLOR-RED-5: #fff1f1;
$THEME-COLOR-RED-6: #ffe7e7;
$THEME-COLOR-PINK-1: #ef627a;
$THEME-COLOR-MAROON-1: #800000;

$THEME_COLOR-RED-BG-1: #f7c0c0;
$THEME_COLOR-RED-BG-2: #f2bba4;

$BRAND-COLOR-BLUE-1: #3960d3;
$BRAND-COLOR-BLUE-2: #5f72d5;

$THEME-COLOR-BLUE-1: #234ed0;
$THEME-COLOR-BLUE-2: #5f72d4;
$THEME-COLOR-BLUE-3: #6375d2;
$THEME-COLOR-BLUE-4: #3046b3;
$THEME-COLOR-BLUE-5: #6476d4;
$THEME-COLOR-BLUE-6: #30333a;
$THEME-COLOR-BLUE-7: #4268d3;
$THEME-COLOR-BLUE-8: #3f63c9;
$THEME-COLOR-BLUE-9: #4b5571;
$THEME-COLOR-BLUE-10: #6a88de;
$THEME-COLOR-BLUE-11: #93a6e0;
$THEME-COLOR-BLUE-12: #4569d6;
$THEME-COLOR-BLUE-13: #6f83a7;
$THEME-COLOR-BLUE-14: #CCD9FF;
$THEME-COLOR-BLUE-15: #D6DEF6;

$THEME-COLOR-PURPLE-1: #b99df3;

$THEME-COLOR-BLUE-BG-1: #9db1eb;
$THEME-COLOR-BLUE-BG-2: #e9edf9;
$THEME-COLOR-BLUE-BG-3: #f7f7fa;
$THEME-COLOR-BLUE-BG-4: #f7f8fa;
$THEME-COLOR-BLUE-BG-5: #e2e7f9;
$THEME-COLOR-BLUE-BG-6: #eff2fb;
$THEME-COLOR-BLUE-BG-7: #e7e7e7;
$THEME-COLOR-BLUE-BG-8: #f9faff;
$THEME-COLOR-BLUE-BG-9: #f2f5ff;
$THEME-COLOR-BLUE-BG-10: #F5F5F5;

$THEME-BG-COLOR-WHITE: $THEME-COLOR-WHITE-1;
$THEME-FONT-COLOR-BLACK-1: $THEME-COLOR-BLACK-1;
$THEME-COLOR-2: #1e90ff;
$THEME-COLOR-3: #4fa6fc;

$THEME-BORDER-COLOR-1: rgba($THEME-COLOR-GRAY-2, 0.12);
$THEME-BORDER-COLOR-2: rgba($THEME-COLOR-BLACK-4, 0.6);
$THEME-BORDER-COLOR-3: rgba($THEME-COLOR-BLACK-1, 0.65);
$THEME-BORDER-COLOR-4: rgba($THEME-COLOR-BLUE-4, 0.45);
$THEME-BORDER-COLOR-5: rgba($THEME-COLOR-WHITE-1, 0.25);
$THEME-BORDER-COLOR-6: #dbe4ff;
$THEME-BORDER-COLOR-7: rgba($THEME-COLOR-BLUE-4, 0.6);
$THEME-BORDER-COLOR-8: rgba($THEME-COLOR-BLUE-4, 0.5);
$THEME-BORDER-COLOR-9: #E9EBF8;
