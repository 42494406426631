@include font-variants("5xl", 3);
@include font-variants("4xl", 2.5);
@include font-variants("3xl", 1.8);
@include font-variants("2xl", 1.3);
@include font-variants("xl", 1.2);
@include font-variants("lg", 1);
@include font-variants("sm", 1);

@media only screen and (min-width: 1300px) {
  @include font-variants("5xl", 3.6);
  @include font-variants("4xl", 3);
  @include font-variants("3xl", 2.2);
  @include font-variants("2xl", 1.6);
  @include font-variants("xl", 1.4);
  @include font-variants("lg", 1.2);
  @include font-variants("sm", 1);
}

@media (max-width: 480px) {
  @include font-variants("5xl", 4.8);
  @include font-variants("4xl", 3.8);
  @include font-variants("3xl", 2.8);
  @include font-variants("2xl", 2.4);
  @include font-variants("xl", 2);
  @include font-variants("lg", 1.6);
  @include font-variants("sm", 1.3);
}
